import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import appearance from './appearance';

const createRootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        appearance
    });

export default createRootReducer;
