import React from 'react';
import { string, func, node } from 'prop-types';
import classNames from 'classnames';
import { I18n } from 'aws-amplify';
import { Button } from 'reactstrap';
// eslint-disable-next-line
import styles from './ProviderButton.module.scss';

const ProviderButton = ({ name, signIn, icon }) => (
    <Button
        onClick={signIn}
        block
        color={null}
        className={classNames(
            styles.root,
            styles[`btn${name}`],
            'mb-3',
            'd-flex',
            'align-items-center'
        )}
    >
        <div className={classNames(styles.logoBox, 'bg-white', 'rounded')}>
            {icon}
        </div>
        <span
            className={classNames(
                styles.providerLabel,
                'position-absolute',
                'text-white'
            )}
        >
            {I18n.get('Continue with')} {name}
        </span>
    </Button>
);

ProviderButton.propTypes = {
    name: string.isRequired,
    signIn: func.isRequired,
    icon: node.isRequired
};

export default ProviderButton;
