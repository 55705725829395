import React from 'react';
import { createMatchSelector } from 'connected-react-router';
import Authenticator from '../../../components/Authenticator';

// Action Types
const TOGGLE_MODAL = 'pallet/appearance/TOGGLE_MODAL';
const SET_MODAL_CHILDREN = 'pallet/appearance/SET_MODAL_CHILDREN';

// Reducer
export default (
    state = {
        show: false,
        children: null
    },
    action = {}
) => {
    switch (action.type) {
        case TOGGLE_MODAL:
            return {
                ...state,
                show: !state.show
            };
        case SET_MODAL_CHILDREN:
            return {
                ...state,
                children: action.children
            };
        default:
            return state;
    }
};

// Action Creators
export const toggleModal = {
    type: TOGGLE_MODAL
};

export const setModalChildren = children => ({
    type: SET_MODAL_CHILDREN,
    children
});

const matchSelector = createMatchSelector('/:authState?');
export const showAuthModal = (state, event) => (dispatch, getState) => {
    if (event) event.preventDefault();
    const toggle = () => dispatch(toggleModal);
    const { params } = matchSelector(getState());
    switch (params.authState) {
        case 'sign-in':
        case 'join':
        case 'forgot-password':
            break;
        default:
            dispatch(
                setModalChildren(
                    <Authenticator authState={state} onModalToggle={toggle} />
                )
            );
            toggle();
    }
};
