import * as React from 'react';
import { func } from 'prop-types';
import classNames from 'classnames';
import { Auth, I18n } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEnvelope,
    faPhone,
    faSignIn,
    faSmilePlus
} from '@fortawesome/pro-solid-svg-icons';
import AuthPiece from '../AuthPiece';
// import { FederatedButtons } from '../FederatedSignIn';
import countryDialCodes from '../common/country-dial-codes';
import capitalize from '../../../util/capitalize';
import styles from './SignUp.module.scss';

const SignInLinkContents = () => (
    <>
        <FontAwesomeIcon icon={faSignIn} className="mr-2" />
        {I18n.get('Sign In')}
    </>
);

class SignUp extends AuthPiece {
    constructor(props) {
        super(props);

        this.validAuthStates = ['signUp'];
        this.signUp = this.signUp.bind(this);
        this.changeSignUpMethod = this.changeSignUpMethod.bind(this);

        this.inputs = {
            dial_code: '+1'
        };

        this.state = {
            method: undefined
        };
    }

    async signUp(event) {
        if (event) event.preventDefault();
        const { method } = this.state;
        const {
            username: _username,
            password,
            givenName,
            familyName,
            dialCode = '+1',
            preferredUsername
        } = this.inputs;

        const getPayload = (username, contactAttribute) => ({
            username,
            password,
            attributes: {
                [contactAttribute]: username,
                given_name: givenName,
                family_name: familyName,
                preferred_username: preferredUsername
            }
        });

        try {
            const payload =
                method === 'phone'
                    ? getPayload(
                          `${dialCode}${_username.replace(/[^0-9]/g, '')}`,
                          'phone_number'
                      )
                    : getPayload(_username, 'email');
            const { userSub } = await Auth.signUp(payload);
            this.changeState('confirmSignUp', userSub);
        } catch (error) {
            this.error(error);
        }
    }

    changeSignUpMethod(method) {
        this.setState({ method });
    }

    showComponent() {
        const {
            // federated,
            // authState,
            // onStateChange,
            onModalToggle
        } = this.props;
        const { method } = this.state;

        const flexCenterClassNames = classNames('d-flex', 'align-items-center');

        const SignUpMethodButton = ({ method: _method, icon, ...props }) => (
            <Button
                onClick={() => this.changeSignUpMethod(_method)}
                block
                color="primary"
                className={classNames(
                    styles.methodBtn,
                    'mb-3',
                    'd-flex',
                    'align-items-center'
                )}
                {...props}
            >
                <div
                    className={classNames(
                        styles.methodIconBox,
                        'bg-white',
                        'rounded'
                    )}
                >
                    <FontAwesomeIcon
                        icon={icon}
                        fixedWidth
                        className={classNames('text-primary', 'align-middle')}
                    />
                </div>
                <span
                    className={classNames(
                        styles.methodLabel,
                        'position-absolute',
                        'text-white'
                    )}
                >
                    {I18n.get('Continue with')} {capitalize(_method)}
                </span>
            </Button>
        );

        return (
            <Form onSubmit={this.signUp}>
                {!method && (
                    <>
                        {/* <FederatedButtons
                            federated={federated}
                            authState={authState}
                            onStateChange={onStateChange}
                        /> */}
                        <SignUpMethodButton method="email" icon={faEnvelope} />
                        <SignUpMethodButton method="phone" icon={faPhone} />
                    </>
                )}
                {method && (
                    <>
                        <FormGroup row>
                            <Label for="givenName" xs={3}>
                                {I18n.get('Name')}
                            </Label>
                            <Col className="pr-2">
                                <Input
                                    type="text"
                                    name="givenName"
                                    id="givenName"
                                    key="givenName"
                                    placeholder="First"
                                    autoFocus
                                    required
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                            <Col className="pl-2">
                                <Input
                                    type="text"
                                    name="familyName"
                                    id="familyName"
                                    key="familyName"
                                    placeholder="Last"
                                    required
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for={method} xs={3}>
                                Username
                            </Label>
                            <Col>
                                <Input
                                    type="text"
                                    name="preferredUsername"
                                    id="preferredUsername"
                                    key="preferredUsername"
                                    maxLength="30"
                                    required
                                    onChange={this.handleInputChange}
                                    pattern="[a-zA-Z0-9-._~]+"
                                    title="Please only use letters, numbers or these symbols: - ~ _ ."
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for={method} xs={3}>
                                {I18n.get(capitalize(method))}
                            </Label>
                            {method === 'phone' && (
                                <Col xs="auto" className="pr-0">
                                    <Input
                                        name="dialCode"
                                        id="dialCode"
                                        type="select"
                                        defaultValue="+1"
                                        onChange={this.handleInputChange}
                                    >
                                        {countryDialCodes.map(dialCode => (
                                            <option
                                                key={dialCode}
                                                value={dialCode}
                                            >
                                                {dialCode}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>
                            )}
                            <Col>
                                <Input
                                    type={method === 'phone' ? 'tel' : method}
                                    name="username"
                                    id="username"
                                    required
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="password" xs={3}>
                                {I18n.get('Password')}
                            </Label>
                            <Col xs={9}>
                                <Input
                                    type="password"
                                    name="password"
                                    id="password"
                                    key="password"
                                    required
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                        </FormGroup>
                        {/* <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    name="subscribedToNewsletter"
                                    id="subscribedToNewsletter"
                                    key="subscribedToNewsletter"
                                />
                                <span className="ml-1">
                                    {I18n.get('Send me Pallet news')}
                                </span>
                            </Label>
                        </FormGroup> */}
                    </>
                )}
                <div className={classNames(flexCenterClassNames, 'my-4')}>
                    <div>
                        {method && (
                            <Button
                                color="link"
                                onClick={() => this.changeSignUpMethod()}
                                className={classNames(
                                    styles.changeMethodBtn,
                                    'p-0',
                                    'border-0'
                                )}
                            >
                                <FontAwesomeIcon
                                    icon={faSmilePlus}
                                    className="mr-1"
                                />
                                {I18n.get('Join a different way')}
                            </Button>
                        )}
                        <div className={flexCenterClassNames}>
                            <div className="mr-2">
                                {I18n.get('Have an account?')}
                            </div>
                            {onModalToggle ? (
                                <Button
                                    color="link"
                                    onClick={() => this.changeState('signIn')}
                                    className={classNames('p-0', 'border-0')}
                                >
                                    <SignInLinkContents />
                                </Button>
                            ) : (
                                <Link
                                    to="/sign-in"
                                    onClick={() => this.changeState('signIn')}
                                >
                                    <SignInLinkContents />
                                </Link>
                            )}
                        </div>
                    </div>
                    {method && (
                        <Button
                            type="submit"
                            color="primary"
                            size="lg"
                            className="ml-auto"
                        >
                            {I18n.get('Join Pallet')}
                        </Button>
                    )}
                </div>
                <small className="text-muted">
                    {I18n.get(
                        'Protecting your data is critical to us at Pallet. By creating a Pallet Account, you agree to our '
                    )}
                    <Link to="/terms" target="_blank">
                        {I18n.get('Terms of Use')}
                    </Link>{' '}
                    {I18n.get(
                        'and the use of your information in accordance with our '
                    )}
                    <Link to="/privacy" target="_blank">
                        {I18n.get('Privacy Policy')}
                    </Link>
                    .
                </small>
            </Form>
        );
    }
}

SignUp.propTypes = {
    onModalToggle: func
};

SignUp.defaultProps = {
    onModalToggle: undefined
};

export default SignUp;
