import React, { Component } from 'react';
import { oneOfType, arrayOf, node } from 'prop-types';
import { Container } from 'reactstrap';
import ErrorAlert from '../ErrorAlert';

// Catches errors from child components

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: undefined };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { error };
    }

    render() {
        const { children } = this.props;
        const { error } = this.state;
        return error ? (
            <Container className="py-5">
                <ErrorAlert error={error} />
            </Container>
        ) : (
            children
        );
    }
}

ErrorBoundary.propTypes = {
    children: oneOfType([arrayOf(node), node]).isRequired
};

export default ErrorBoundary;
