import React, { useEffect } from 'react';
import { shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Analytics } from 'aws-amplify';
import ReactGA from 'react-ga';
import withUser from '../withUser';
import packageInfo from '../../../package.json';

/*
 * Configures Google Analytics, AWS Pinpoint, and Intercom
 * Warning: Only include this file once as a HOC for App.js!

 * https://analytics.google.com/analytics/web/
 * https://us-west-2.console.aws.amazon.com/pinpoint/home
 * https://www.intercom.com/
 */

const isNotDevelopment = process.env.NODE_ENV !== 'development';

// Initialize Google Analytics in production
if (isNotDevelopment)
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, {
        gaOptions: {
            appName: process.env.REACT_APP_TITLE,
            appId: process.env.REACT_APP_ID,
            appVersion: packageInfo.version,
            anonymizeIp: true,
            forceSSL: true,
            siteSpeedSampleRate: 25
        }
    });

const withAnalytics = WrappedComponent => {
    const AnalyticsComponent = ({ pathname, search, user }) => {
        useEffect(() => {
            let isMounted = true;

            // Only use analytics in production
            if (isMounted && isNotDevelopment) {
                const userId = user && user.id ? { userId: user.id } : null;

                // Google Analytics User Tracking
                if (userId) ReactGA.set(userId);

                // Google Analytics Page View Tracking
                ReactGA.pageview(pathname + search);

                // AWS Pinpoint Common Configuration
                const pinpointConfig = {
                    enable: true,
                    provider: 'AWSPinpoint',
                    ...(userId ? { attributes: userId } : {})
                };

                const pinpointTrackers = {
                    // AWS Pinpoint Session Tracking
                    // https://aws-amplify.github.io/docs/js/analytics#session-tracking
                    session: pinpointConfig,
                    // AWS Pinpoint Page View Tracking
                    // https://aws-amplify.github.io/docs/js/analytics#page-view-tracking
                    pageView: {
                        eventName: 'pageView',
                        type: 'SPA',
                        ...pinpointConfig
                    },
                    // AWS Pinpoint Page Event Tracking
                    // https://aws-amplify.github.io/docs/js/analytics#page-event-tracking
                    event: {
                        events: ['click', 'hover'],
                        selectorPrefix: 'data-event-',
                        ...pinpointConfig
                    }
                };

                // Call AWS Pinpoint trackers
                Object.keys(pinpointTrackers).forEach(method =>
                    Analytics.autoTrack(method, pinpointTrackers[method])
                );
            }

            /*
                * Intercom (disabled: not currently subscribed)
                const intercomUser = user.id
                    ? {
                        id: user.id,
                        name: `${user.givenName} ${user.familyName}`,
                        email: user.email,
                        phone: user.phone
                    }
                    : {};

                const intercomConfig = {
                    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                    ...intercomUser
                };

                window.Intercom('boot', intercomConfig);

                if (!window.intercomSettings.id)
                    window.intercomSettings = intercomConfig;
                */

            return () => {
                isMounted = false;
            };
        }, [user, pathname, search]);

        return <WrappedComponent />;
    };

    AnalyticsComponent.propTypes = {
        pathname: string.isRequired,
        search: string,
        user: shape({
            id: string
        })
    };

    AnalyticsComponent.defaultProps = {
        search: '',
        user: undefined
    };

    const mapStateToProps = ({
        router: {
            location: { pathname, search }
        }
    }) => ({
        pathname,
        search
    });

    return compose(withUser, connect(mapStateToProps))(AnalyticsComponent);
};

export default withAnalytics;
