/*
 * Copyright 2017-2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { Component } from 'react';
import { func } from 'prop-types';
import { Auth, Logger } from 'aws-amplify';
import Constants from '../../common/constants';
import { ReactComponent as GoogleLogo } from './google.svg';
import ProviderButton from '../ProviderButton';

const logger = new Logger('withGoogle');

const withGoogle = WrappedComponent =>
    class GoogleComponent extends Component {
        constructor(props) {
            super(props);

            this.initGapi = this.initGapi.bind(this);
            this.signIn = this.signIn.bind(this);
            this.federatedSignIn = this.federatedSignIn.bind(this);

            this.state = {};
        }

        componentDidMount() {
            const { googleClientId } = this.props;
            const ga =
                window.gapi && window.gapi.auth2
                    ? window.gapi.auth2.getAuthInstance()
                    : null;
            if (googleClientId && !ga) this.createScript();
        }

        async signIn() {
            const ga = window.gapi.auth2.getAuthInstance();
            const { onError } = this.props;
            try {
                const googleUser = await ga.signIn();
                logger.debug('Retrieved Google User: ', googleUser);
                await this.federatedSignIn(googleUser);
                const payload = { provider: Constants.GOOGLE };
                try {
                    localStorage.setItem(
                        Constants.AUTH_SOURCE_KEY,
                        JSON.stringify(payload)
                    );
                } catch (error) {
                    logger.debug(
                        'Failed to cache auth source into localStorage',
                        error
                    );
                }
            } catch (error) {
                if (onError) onError(error);
                else logger.error(error);
            }
        }

        async federatedSignIn({ getAuthResponse, getBasicProfile }) {
            const { idToken, expiresAt } = getAuthResponse();
            const { getEmail, getGivenName, getFamilyName } = getBasicProfile();
            const { onStateChange } = this.props;
            logger.debug('Signing in with Google');
            try {
                await Auth.federatedSignIn(
                    'google',
                    { token: idToken, expiresAt },
                    {
                        email: getEmail(),
                        given_name: getGivenName(),
                        family_name: getFamilyName()
                    }
                );
                const user = await Auth.currentAuthenticatedUser();
                if (onStateChange) onStateChange('signedIn', user);
            } catch (error) {
                logger.error(error);
            }
        }

        createScript() {
            const script = document.createElement('script');
            script.src = 'https://apis.google.com/js/platform.js';
            script.async = true;
            script.onload = this.initGapi;
            document.body.appendChild(script);
        }

        initGapi() {
            logger.debug('init gapi');
            const { googleClientId } = this.props;
            const g = window.gapi;
            g.load('auth2', () => {
                g.auth2.init({
                    client_id: googleClientId,
                    scope: 'profile email openid'
                });
            });
        }

        render() {
            const ga =
                window.gapi && window.gapi.auth2
                    ? window.gapi.auth2.getAuthInstance()
                    : null;
            const signOut = async () => {
                if (!ga) return Promise.resolve();
                try {
                    const googleAuth = await ga;
                    logger.debug('google signing out');
                    return googleAuth.signOut();
                } catch (error) {
                    logger.debug('google Auth undefined');
                    return Promise.resolve();
                }
            };
            return (
                <WrappedComponent
                    {...this.props}
                    ga={ga}
                    googleSignIn={this.signIn}
                    googleSignOut={signOut}
                />
            );
        }
    };

const GoogleIcon = <GoogleLogo className="m-1" />;

const AuthButton = ({ googleSignIn }) => (
    <ProviderButton name="Google" signIn={googleSignIn} icon={GoogleIcon} />
);

AuthButton.propTypes = {
    googleSignIn: func.isRequired
};

export const GoogleButton = withGoogle(AuthButton);

export default withGoogle;
