import React from 'react';
import { shape, func } from 'prop-types';
import { Auth, I18n, Logger } from 'aws-amplify';
import { connect } from 'react-redux';
import { createMatchSelector, push as pushRoute } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faRedo,
    faSignIn,
    faSpinnerThird
} from '@fortawesome/pro-solid-svg-icons';
import AuthPiece from './AuthPiece';

const logger = new Logger('ConfirmSignUp');

const matchSelector = createMatchSelector(`/join/confirm/:code/:userId`);
const matchParams = router => {
    const match = matchSelector({ router });
    return match ? match.params : {};
};

class ConfirmSignUp extends AuthPiece {
    constructor(props) {
        super(props);

        this.validAuthStates = ['confirmSignUp'];
        this.confirm = this.confirm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleParams = this.confirm.bind(this);
        this.resend = this.resend.bind(this);

        this.state = { loading: false, params: {} };
    }

    // For email verification link and CognitoCustomMessage Lambda
    async componentDidMount() {
        const { router, onModalToggle } = this.props;
        if (onModalToggle) return;
        const params = matchParams(router);
        this.setState({ params });
        if (params.userId && params.code) await this.confirm(params);
    }

    async confirm(
        { userId, code } = {
            userId: this.usernameFromAuthData() || this.inputs.username,
            code: this.inputs.code
        }
    ) {
        const { onModalToggle, push } = this.props;
        try {
            this.setState({ loading: true });
            await Auth.confirmSignUp(userId, code);
            if (!onModalToggle) push('/sign-in');
            this.changeState('signedUp');
            // TODO: Remove the above line, refactor SignIn.js to Hooks, extract
            // and export the class method signIn as a function, and call it here.
        } catch (err) {
            if (
                err.message ===
                'User cannot be confirmed. Current status is CONFIRMED'
            ) {
                logger.warn('User is already confirmed');
                if (onModalToggle) this.changeState('signIn');
                else push('/sign-in');
            } else {
                this.error(err);
                logger.error(`Failed to verify sign up: ${err.message}`);
            }
        }
        this.setState({ loading: false });
    }

    async handleSubmit(event) {
        if (event) event.preventDefault();
        await this.confirm();
    }

    async resend() {
        const username = this.usernameFromAuthData() || this.inputs.username;
        try {
            await Auth.resendSignUp(username);
            logger.debug(`Resent verification code to ${username}`);
        } catch (err) {
            this.error(err);
        }
    }

    showComponent() {
        const { onModalToggle } = this.props;
        const { loading, params } = this.state;
        const username = params.userId || this.usernameFromAuthData();

        const SignInLinkContents = () => (
            <>
                <FontAwesomeIcon icon={faSignIn} className="mr-1" />
                {I18n.get('Back to Sign In')}
            </>
        );

        if (loading)
            return (
                <div
                    className={classNames('text-primary', 'text-center', 'p-5')}
                >
                    <FontAwesomeIcon icon={faSpinnerThird} size="3x" spin />
                </div>
            );

        return (
            <Form onSubmit={this.handleSubmit}>
                <div className="mb-4">
                    {I18n.get(
                        'We sent you a code. Please enter it here to verify your account.'
                    )}
                </div>
                {username ? (
                    <Input
                        type="hidden"
                        name="username"
                        value={username || ''}
                        onChange={this.handleInputChange}
                    />
                ) : (
                    <FormGroup row className="align-items-center">
                        <Label for="username" xs={3}>
                            {I18n.get('User')}
                        </Label>
                        <Col xs={9}>
                            <Input
                                type="text"
                                name="username"
                                id="username"
                                bsSize="lg"
                                placeholder={I18n.get('Email or Phone')}
                                autoFocus
                                required
                                onChange={this.handleInputChange}
                            />
                        </Col>
                    </FormGroup>
                )}
                <FormGroup row className="align-items-center">
                    <Label for="code" xs={2}>
                        {I18n.get('Code')}
                    </Label>
                    <Col xs={10}>
                        <Input
                            type="text"
                            name="code"
                            id="code"
                            bsSize="lg"
                            autoFocus={!username}
                            required
                            autoComplete="off"
                            onChange={this.handleInputChange}
                        />
                    </Col>
                </FormGroup>
                <Row
                    className={classNames(
                        'mt-4',
                        'd-flex',
                        'align-items-center'
                    )}
                >
                    <Col xs="auto">
                        <Button
                            color="link"
                            onClick={this.resend}
                            className="border-0 p-0"
                        >
                            <FontAwesomeIcon icon={faRedo} className="mr-1" />
                            {I18n.get('Resend Code')}
                        </Button>
                        <br />
                        {onModalToggle ? (
                            <Button
                                color="link"
                                onClick={() => this.changeState('signIn')}
                                className="border-0 p-0"
                            >
                                <SignInLinkContents />
                            </Button>
                        ) : (
                            <Link
                                to="/sign-in"
                                onClick={() => this.changeState('signIn')}
                            >
                                <SignInLinkContents />
                            </Link>
                        )}
                    </Col>
                    <Col className="text-right">
                        <Button type="submit" color="primary" size="lg">
                            {I18n.get('Confirm')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

ConfirmSignUp.propTypes = {
    router: shape({}).isRequired,
    push: func.isRequired,
    onModalToggle: func
};

ConfirmSignUp.defaultProps = {
    onModalToggle: undefined
};

const mapStateToProps = ({ router }) => ({ router });

const mapDispatchToProps = {
    push: pushRoute
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSignUp);
