/*
 * Copyright 2017-2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { Component } from 'react';
import { string, func } from 'prop-types';
import classNames from 'classnames';
import { Auth, Logger } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import Constants from '../../common/constants';
import ProviderButton from '../ProviderButton';
import styles from '../ProviderButton/ProviderButton.module.scss';

const logger = new Logger('withFacebook');

const withFacebook = WrappedComponent =>
    class FacebookComponent extends Component {
        constructor(props) {
            super(props);

            this.fbAsyncInit = this.fbAsyncInit.bind(this);
            this.signIn = this.signIn.bind(this);
            this.federatedSignIn = this.federatedSignIn.bind(this);
        }

        state = {};

        componentDidMount() {
            const { facebookAppId } = this.props;
            if (facebookAppId && !window.FB) this.createScript();
        }

        async signIn() {
            const fb = window.FB;
            await fb.getLoginStatus(async response => {
                try {
                    localStorage.setItem(
                        Constants.AUTH_SOURCE_KEY,
                        JSON.stringify({ provider: Constants.FACEBOOK })
                    );
                } catch (err) {
                    logger.debug(
                        'Failed to cache auth source into localStorage',
                        err
                    );
                }
                if (response.status === 'connected')
                    await this.federatedSignIn(response.authResponse);
                else
                    fb.login(
                        async res =>
                            res && res.authResponse
                                ? this.federatedSignIn(res.authResponse)
                                : null,
                        {
                            scope: 'public_profile,email'
                        }
                    );
            });
        }

        async federatedSignIn(response) {
            /* eslint-disable camelcase */
            logger.debug(response);
            const { onStateChange } = this.props;
            const { accessToken, expiresIn } = response;
            if (!accessToken) return;
            const date = new Date();
            const expiresAt = expiresIn * 1000 + date.getTime();
            const fb = window.FB;
            try {
                fb.api(
                    '/me',
                    { fields: 'email,first_name,last_name' },
                    async ({ email, first_name, last_name }) => {
                        const attributes = {
                            email,
                            given_name: first_name,
                            family_name: last_name
                        };
                        await Auth.federatedSignIn(
                            'facebook',
                            { token: accessToken, expires_at: expiresAt },
                            attributes
                        );
                        const user = await Auth.currentAuthenticatedUser();
                        if (onStateChange) onStateChange('signedIn', user);
                    }
                );
            } catch (error) {
                logger.error(error);
            }
        }

        fbAsyncInit() {
            logger.debug('init FB');
            const { facebookAppId } = this.props;
            const fb = window.FB;
            fb.init({
                appId: facebookAppId,
                cookie: true,
                xfbml: true,
                version: 'v3.2'
            });
            fb.getLoginStatus(response => logger.debug(response));
        }

        createScript() {
            window.fbAsyncInit = this.fbAsyncInit;
            const script = document.createElement('script');
            script.src = 'https://connect.facebook.net/en_US/sdk.js';
            script.async = true;
            document.body.appendChild(script);
        }

        render() {
            const fb = window.FB;
            const signOut = async () => {
                if (!fb) {
                    logger.debug('FB sdk undefined');
                    return Promise.resolve();
                }
                return fb.getLoginStatus(({ status }) =>
                    status === 'connected'
                        ? new Promise(resolve => {
                              logger.debug('facebook signing out');
                              fb.logout(logOutRes => {
                                  resolve(logOutRes);
                              });
                          })
                        : Promise.resolve()
                );
            };

            FacebookComponent.propTypes = {
                facebookAppId: string,
                onStateChange: func
            };

            FacebookComponent.defaultProps = {
                facebookAppId: undefined,
                onStateChange: undefined
            };

            return (
                <WrappedComponent
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...this.props}
                    fb={fb}
                    facebookSignIn={this.signIn}
                    facebookSignOut={signOut}
                />
            );
        }
    };

const FacebookIcon = (
    <FontAwesomeIcon
        icon={faFacebookF}
        className={classNames(styles.icon, styles.facebookIcon)}
    />
);

const AuthButton = ({ facebookSignIn }) => (
    <ProviderButton
        name="Facebook"
        signIn={facebookSignIn}
        icon={FacebookIcon}
    />
);

AuthButton.propTypes = {
    facebookSignIn: func.isRequired
};

export const FacebookButton = withFacebook(AuthButton);

export default withFacebook;
