import React from 'react';
import { Auth, I18n, Logger, JS } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn } from '@fortawesome/pro-solid-svg-icons';
import capitalize from '../../util/capitalize';
import AuthPiece from './AuthPiece';

const logger = new Logger('RequireNewPassword');

const convertToHumanString = str =>
    str
        .split('_')
        .map(capitalize)
        .join(' ');

const objectWithProperties = (obj, keys) =>
    Object.keys(obj).reduce((acc, key) => {
        if (
            keys.indexOf(key) !== -1 &&
            Object.prototype.hasOwnProperty.call(obj, key)
        )
            acc[key] = obj[key];
        return acc;
    }, {});

export default class RequireNewPassword extends AuthPiece {
    constructor(props) {
        super(props);

        this.validAuthStates = ['requireNewPassword'];
        this.reset = this.reset.bind(this);
        this.checkContact = this.checkContact.bind(this);
    }

    async checkContact(user) {
        const data = await Auth.verifiedContact(user);
        if (!JS.isEmpty(data.verified)) this.changeState('signedIn', user);
        else this.changeState('verifyContact', Object.assign(user, data));
    }

    async reset() {
        const user = this.props.authData;
        const { password } = this.inputs;
        const { requiredAttributes } = user.challengeParam;
        const attrs = objectWithProperties(this.inputs, requiredAttributes);

        try {
            const resUser = await Auth.completeNewPassword(
                user,
                password,
                attrs
            );
            logger.debug('complete new password', resUser);
            if (resUser.challengeName === 'SMS_MFA')
                this.changeState('confirmSignIn', resUser);
            else if (resUser.challengeName === 'MFA_SETUP') {
                logger.debug('TOTP setup', resUser.challengeParam);
                this.changeState('TOTPSetup', resUser);
            } else this.checkContact(resUser);
        } catch (error) {
            this.error(error);
        }
    }

    showComponent() {
        const { hide, onModalToggle } = this.props;

        if (hide && hide.includes(RequireNewPassword)) return null;

        const user = this.props.authData;
        const { requiredAttributes } = user.challengeParam;

        const SignInLinkContents = () => (
            <>
                <FontAwesomeIcon icon={faSignIn} className="mr-2" />
                {I18n.get('Back to Sign In')}
            </>
        );

        return (
            <Form onSubmit={this.reset}>
                <div className="mb-4">
                    {I18n.get('Your account requires a new password.')}
                </div>
                <FormGroup row>
                    <Label for="username" xs={3}>
                        {I18n.get('User')}
                    </Label>
                    <Col xs={9}>
                        <Input
                            type="text"
                            placeholder={I18n.get('Email or Phone')}
                            key="username"
                            name="username"
                            autoFocus
                            required
                            onChange={this.handleInputChange}
                        />
                    </Col>
                </FormGroup>
                {requiredAttributes.map(attribute => {
                    const humanAttribute = I18n.get(
                        convertToHumanString(attribute)
                    );
                    return (
                        <FormGroup row>
                            <Label for={attribute} xs={3}>
                                {humanAttribute}
                            </Label>
                            <Col xs={9}>
                                <Input
                                    type="text"
                                    placeholder={humanAttribute}
                                    key={attribute}
                                    name={attribute}
                                    required
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                        </FormGroup>
                    );
                })}
                <Row className="mt-4 d-flex align-items-center">
                    <Col xs="auto">
                        {onModalToggle ? (
                            <Button
                                color="link"
                                onClick={() => this.changeState('signIn')}
                                className="p-0 border-0"
                            >
                                <SignInLinkContents />
                            </Button>
                        ) : (
                            <Link
                                to="/sign-in"
                                onClick={() => this.changeState('signIn')}
                            >
                                <SignInLinkContents />
                            </Link>
                        )}
                    </Col>
                    <Col className="text-right">
                        <Button type="submit" color="primary" size="lg">
                            {I18n.get('Reset')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}
