import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal as BSModal } from 'reactstrap';
import { toggleModal } from '../../state/modules/appearance/modal';
import styles from './Modal.module.scss';

const Modal = props => {
    const { show, children } = useSelector(
        ({ appearance }) => appearance.modal
    );

    const dispatch = useDispatch();
    const toggle = useCallback(() => dispatch(toggleModal), [dispatch]);

    return (
        <BSModal
            isOpen={Boolean(show && children)}
            toggle={toggle}
            className={styles.modal}
        >
            {children}
        </BSModal>
    );
};

export default Modal;
