import React from 'react';
import { string, oneOfType, object, array } from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SocialLink = ({ name, icon, className, ...props }) => (
    <a
        {...props}
        aria-label={name}
        target="_blank"
        rel="noopener noreferrer"
        className={classNames(className, 'text-secondary')}
    >
        <FontAwesomeIcon icon={icon} fixedWidth size="2x" />
    </a>
);

SocialLink.propTypes = {
    name: string.isRequired,
    href: string.isRequired,
    icon: oneOfType([object, array, string]).isRequired,
    className: string
};

SocialLink.defaultProps = {
    className: ''
};

export default SocialLink;
