import React from 'react';
import { hydrate, render } from 'react-dom';
import Loadable from 'react-loadable';
import Amplify /* , {  Auth, Storage } */ from 'aws-amplify';
import configureStore from './state/store';
import * as serviceWorker from './util/serviceWorker';
import ApolloClient from './components/ApolloClient';
import ReduxProvider from './components/ReduxProvider';
import ErrorBoundary from './components/ErrorBoundary';
import App from './components/App';
import awsExports from './aws-exports';

/*
 * Connect React to ApolloClient and Redux store and render
 * TODO: As we eventually migrate from REST/MySQL to GraphQL/DynamoDB,
 *       transition from Redux/Apollo to full-Apollo. We currently use both.
 */

const { store, persistor, history } = configureStore();
const ConnectedRoot = () => (
    <ErrorBoundary>
        <ApolloClient>
            <ReduxProvider
                store={store}
                persistor={persistor}
                history={history}
            >
                <App />
            </ReduxProvider>
        </ApolloClient>
    </ErrorBoundary>
);
const rootElement = document.getElementById('root');

// Loadable is used for code splitting
Loadable.preloadReady().then(() =>
    rootElement.hasChildNodes()
        ? hydrate(<ConnectedRoot />, rootElement)
        : render(<ConnectedRoot />, rootElement)
);

// Configure AWS Amplify
Amplify.configure(awsExports);

Amplify.Logger.LOG_LEVEL =
    process.env.NODE_ENV === 'development' ? 'DEBUG' : 'INFO';

// Service Worker
// https://facebook.github.io/create-react-app/docs/making-a-progressive-web-app
serviceWorker.unregister();
