import React from 'react';
import { node, shape, func, bool, string } from 'prop-types';
import classNames from 'classnames';
import { I18n } from 'aws-amplify';
import { Container, Alert, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AuthenticatorContainer = ({
    children,
    header,
    onModalToggle,
    showAlert,
    error
}) =>
    onModalToggle ? (
        <>
            <ModalHeader toggle={onModalToggle} className="align-items-center">
                <FontAwesomeIcon icon={header.icon} className="mr-2" />
                {I18n.get(header.text)}
            </ModalHeader>
            <ModalBody className="p-sm-5">
                <Container>
                    {showAlert && <Alert color="danger">{error}</Alert>}
                    {children}
                </Container>
            </ModalBody>
        </>
    ) : (
        <>
            {header && (
                <h4 className={classNames('mb-4', 'text-center')}>
                    <FontAwesomeIcon icon={header.icon} className="mr-2" />
                    {I18n.get(header.text)}
                </h4>
            )}
            <Container
                className={classNames('p-4', 'p-sm-5', 'bg-white', 'rounded')}
            >
                {showAlert && <Alert color="danger">{error}</Alert>}
                {children}
            </Container>
        </>
    );

AuthenticatorContainer.propTypes = {
    children: node.isRequired,
    header: shape({}).isRequired,
    onModalToggle: func,
    showAlert: bool,
    error: string
};

AuthenticatorContainer.defaultProps = {
    onModalToggle: undefined,
    showAlert: false,
    error: undefined
};

export default AuthenticatorContainer;
