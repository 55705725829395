/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:e28838dc-fba8-4ef0-9348-44774125916b",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_c0mIeFzgN",
    "aws_user_pools_web_client_id": "7d1mokcdbnrdkql1t9sfmjc1ke",
    "oauth": {},
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_mobile_analytics_app_id": "627aee6f758347bd8a99fc04f4ef5356",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://5wdpaa2c3je4xdekqrcnffbpty.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "pallet-user-content-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
