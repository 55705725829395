import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import home from './home';
import modal from './modal';

const homePersistConfig = {
    key: 'home',
    storage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['activeFilter']
};

const appearance = combineReducers({
    home: persistReducer(homePersistConfig, home),
    modal
});

export default appearance;
