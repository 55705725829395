import React from 'react';
import { string, shape } from 'prop-types';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import { Alert, Media } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import '../../styles/alert-content.scss';

const isDevelopment = process.env.NODE_ENV === 'development';

const ErrorAlert = ({ friendlyError, error }) => (
    <Alert color="danger">
        <Helmet title="Error" />
        <Media>
            <Media left className="mr-3">
                <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
            </Media>
            <Media body className="alert-content">
                <span>
                    <pre
                        className={classNames(
                            'text-reset',
                            'font-weight-bold',
                            'alert-title'
                        )}
                    >
                        {(isDevelopment && error.message) || friendlyError}
                    </pre>
                    {isDevelopment && (
                        <pre>{JSON.stringify(error, null, 2)}</pre>
                    )}
                </span>
            </Media>
        </Media>
    </Alert>
);

ErrorAlert.propTypes = {
    friendlyError: string,
    error: shape({}).isRequired
};

ErrorAlert.defaultProps = {
    friendlyError: 'An error occurred'
};

export default ErrorAlert;
