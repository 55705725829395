import React from 'react';
import { oneOfType, arrayOf, node } from 'prop-types';
import { Auth } from 'aws-amplify';
import AWSAppSyncClient from 'aws-appsync';
import { ApolloProvider } from 'react-apollo';
// import { Rehydrated } from 'aws-appsync-react';
import awsExports from '../../aws-exports';

const client = new AWSAppSyncClient({
    url: awsExports.aws_appsync_graphqlEndpoint,
    region: awsExports.aws_appsync_region,
    auth: {
        type: awsExports.aws_appsync_authenticationType,
        jwtToken: async () =>
            (await Auth.currentSession()).getIdToken().getJwtToken()
    },
    complexObjectsCredentials: () => Auth.currentCredentials(),
    disableOffline: true // TODO: TEMP fix! https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/450
});

const ApolloClient = ({ children }) => (
    <ApolloProvider
        client={client}
        connectToDevTools={process.env.NODE_ENV === 'development'}
        addTypename
    >
        {children}
        {/*
            The below line currently results in an error:
            `Unhandled Rejection (TypeError): Cannot read property 'hydrated' of undefined`
            Check for updates to aws-appsync-react module

            <Rehydrated>{children}</Rehydrated>
        */}
    </ApolloProvider>
);

ApolloClient.propTypes = {
    children: oneOfType([arrayOf(node), node]).isRequired
};

export default ApolloClient;
