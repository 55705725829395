import React, { useState, useCallback, useEffect } from 'react';
import { string, func, shape, oneOfType } from 'prop-types';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { I18n, Logger, Hub, Auth } from 'aws-amplify';
import {
    faSignIn,
    faSmilePlus,
    faKey,
    faCheckCircle
} from '@fortawesome/pro-solid-svg-icons';
import AuthenticatorContainer from './AuthenticatorContainer';
import SignIn from './SignIn';
import ConfirmSignIn from './ConfirmSignIn';
import RequireNewPassword from './RequireNewPassword';
import SignUp from './SignUp';
import Loading from './Loading';
import ConfirmSignUp from './ConfirmSignUp';
import VerifyContact from './VerifyContact';
import ForgotPassword from './ForgotPassword';
import TOTPSetup from './TOTPSetup';
import MessageMap from './MessageMap';
import withUser from '../withUser';

const logger = new Logger('Authenticator');
const AUTHENTICATOR_AUTHSTATE = 'amplify-authenticator-authState';

const signIn = 'signIn';

const headerStates = [
    {
        state: signIn,
        icon: faSignIn,
        text: 'Sign In to Pallet'
    },
    {
        state: 'confirmSignIn',
        icon: faKey,
        text: "Confirm It's You"
    },
    {
        state: 'signUp',
        icon: faSmilePlus,
        text: 'Join Pallet'
    },
    {
        state: 'confirmSignUp',
        icon: faCheckCircle,
        text: 'Verify Account'
    },
    {
        state: 'signedUp',
        icon: faSmilePlus,
        text: "You're signed up. Welcome to Pallet!"
    },
    {
        state: 'verifyContact',
        icon: faCheckCircle,
        text: 'Verify Contact'
    },
    {
        state: 'forgotPassword',
        icon: faKey,
        text: 'Reset Password'
    },
    {
        state: 'requireNewPassword',
        icon: faKey,
        text: 'Reset Password'
    },
    {
        state: 'TOTPSetup',
        icon: faKey,
        text: 'Set Up Two-Factor Authentication'
    }
];

const getAuthStateFromPath = pathname => {
    switch (pathname) {
        case '/sign-in':
            return signIn;
        case '/join':
            return 'signUp';
        case '/reset-password':
            return 'forgotPassword';
        default:
            return null;
    }
};

// TODO: Check if this is still necessary -- holdover from AWS React code
const filterSignedOutState = state => (state === 'signedOut' ? signIn : state);

const Authenticator = ({
    user,
    signOut,
    onModalToggle,
    authState: _authState,
    authData: _authData,
    errorMessage
}) => {
    const { pathname, target, showModal } = useSelector(
        ({ router: { location }, appearance: { modal } }) => ({
            pathname: location.pathname,
            target: (location.state && location.state.target) || '/',
            showModal: modal.show
        })
    );

    const isModal = Boolean(onModalToggle);
    const authStateFromPath = getAuthStateFromPath(pathname);
    const initialAuthState =
        (user && 'signedIn') ||
        authStateFromPath ||
        filterSignedOutState(_authState);
    const [authState, setAuthState] = useState(initialAuthState);
    const [authData, setAuthData] = useState(_authData);
    const [error, setError] = useState();
    const [showAlert, setShowAlert] = useState(false);

    const handleStateChange = useCallback(
        (state, data) => {
            if (state === authState) return;
            const newAuthState = filterSignedOutState(state);
            try {
                localStorage.setItem(AUTHENTICATOR_AUTHSTATE, newAuthState);
                setAuthState(newAuthState);
                setAuthData(data);
                setError(null);
                setShowAlert(false);
            } catch (err) {
                logger.error(
                    'Failed to store the auth state in localStorage',
                    err
                );
            }
        },
        [authState]
    );

    const handleAuthEvent = (event, _showAlert = true) => {
        if (event.type === 'error') {
            const map = errorMessage || MessageMap;
            setError(typeof map === 'string' ? map : map(event.data));
            setShowAlert(_showAlert);
        }
    };

    useEffect(() => {
        let isMounted = true;

        const checkUser = async () => {
            if (!isMounted) return;
            try {
                const cognitoUser = await Auth.currentAuthenticatedUser();
                handleStateChange('signedIn', cognitoUser);
            } catch (err) {
                // if (authState !== 'signedIn' && user)
                let cachedAuthState = null;
                try {
                    cachedAuthState = localStorage.getItem(
                        AUTHENTICATOR_AUTHSTATE
                    );
                } catch (e) {
                    logger.debug(
                        'Failed to get the auth state from localStorage',
                        e
                    );
                }
                if (cachedAuthState === 'signedIn') {
                    await signOut();
                    handleStateChange(initialAuthState);
                }
            }
        };

        const HubListener = () =>
            Hub.listen(
                'auth',
                ({ payload }) =>
                    isMounted &&
                    payload.event === 'parsingUrl_failure' &&
                    handleStateChange(signIn, null)
            );

        if (isMounted && !isModal) setAuthState(initialAuthState);
        checkUser();
        HubListener();

        return () => {
            Hub.remove('auth');
            isMounted = false;
        };
    }, [isModal, initialAuthState, signOut, handleStateChange]);

    if (authState === 'signedIn') {
        if (isModal && showModal) {
            onModalToggle();
            return null;
        }
        return (
            <Redirect
                to={{
                    pathname: target,
                    state: {}
                }}
            />
        );
    }

    const header = headerStates.find(({ state }) => state === authState);

    return (
        <AuthenticatorContainer
            header={header}
            showAlert={showAlert}
            error={error}
            onModalToggle={onModalToggle}
        >
            {!isModal && <Helmet title={I18n.get(header.text)} />}
            {React.Children.map(
                [
                    <SignIn key={1} />,
                    <ConfirmSignIn key={2} />,
                    <RequireNewPassword key={3} />,
                    <SignUp key={4} />,
                    <ConfirmSignUp key={5} />,
                    <VerifyContact key={6} />,
                    <ForgotPassword key={7} />,
                    <TOTPSetup key={8} />,
                    <Loading key={9} />
                ],
                child =>
                    React.cloneElement(child, {
                        key: child.key,
                        federated: {
                            googleClientId:
                                process.env.REACT_APP_GOOGLE_CLIENT_ID,
                            facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID
                        },
                        authState,
                        authData,
                        onStateChange: handleStateChange,
                        onAuthEvent: handleAuthEvent,
                        onModalToggle
                    })
            )}
        </AuthenticatorContainer>
    );
};

Authenticator.propTypes = {
    user: shape({}),
    signOut: func.isRequired,
    onModalToggle: func,
    authState: string,
    authData: oneOfType([shape({}), string]),
    errorMessage: func
};

Authenticator.defaultProps = {
    user: undefined,
    onModalToggle: undefined,
    authState: 'signIn',
    authData: undefined,
    errorMessage: undefined
};

export default withUser(Authenticator);
