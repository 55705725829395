import gql from 'graphql-tag';
import {
    // company,
    category,
    project,
    message,
    userWithoutContact,
    s3Object
} from './fragments';

export const getProjectCategory = gql`
    query GetProjectCategory($id: ID!, $level: Int!) {
        getProjectCategory(id: $id, level: $level) {
            ...ProjectCategoryFragment
        }
    }
    ${category}
`;

export const listTopLevelProjectCategories = gql`
    query ListTopLevelProjectCategories {
        listTopLevelProjectCategories {
            ...ProjectCategoryFragment
        }
    }
    ${category}
`;

export const listProjectTypes = gql`
    query ListProjectTypes {
        listProjectTypes {
            level
            name
        }
    }
`;

export const getProject = gql`
    query GetProject($id: ID!) {
        getProject(id: $id) {
            ...ProjectFragment
        }
    }
    ${project}
`;

export const listProjects = gql`
    query ListProjects($filter: ModelProjectFilterInput) {
        listProjects(filter: $filter) {
            items {
                ...ProjectFragment
            }
            nextToken
        }
    }
    ${project}
`;

export const getBid = gql`
    query GetBid($id: ID!) {
        getBid(id: $id) {
            id
            project {
                id
                owner {
                    id
                    givenName
                    familyName
                    username
                }
            }
            conversation {
                id
            }
            userBidder {
                id
                givenName
                familyName
                username
            }
            events {
                timestamp
                status
            }
        }
    }
`;

export const getUser = gql`
    query GetUser($id: ID!) {
        getUser(id: $id) {
            ...UserWithoutContact
            email
            phoneNumber
        }
    }
    ${userWithoutContact}
`;

export const getConvo = gql`
    query GetConvo($id: ID!, $nextToken: String) {
        getConvo(id: $id) {
            id
            messages(sortDirection: DESC, limit: 20, nextToken: $nextToken) {
                nextToken
                items {
                    ...MessageFragment
                }
            }
        }
    }
    ${message}
`;

export const searchMessages = gql`
    query SearchMessages(
        $filter: SearchableMessageFilterInput
        $sort: SearchableMessageSortInput
        $limit: Int
        $nextToken: Int
    ) {
        searchMessages(
            filter: $filter
            sort: $sort
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...MessageFragment
            }
            nextToken
        }
    }
    ${message}
`;

export const searchUsers = gql`
    query SearchUsers(
        $filter: SearchableUserFilterInput
        $sort: SearchableUserSortInput
        $limit: Int
        $nextToken: String
    ) {
        searchUsers(
            filter: $filter
            sort: $sort
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                givenName
                familyName
                registered
                userConversations {
                    items {
                        id
                        name
                        status
                        convoLinkUserId
                    }
                    nextToken
                }
            }
            nextToken
        }
    }
`;

export const searchConvoLinks = gql`
    query SearchConvoLinks(
        $filter: SearchableConvoLinkFilterInput
        $sort: SearchableConvoLinkSortInput
        $limit: Int
        $nextToken: Int
    ) {
        searchConvoLinks(
            filter: $filter
            sort: $sort
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                status
                convoLinkUserId
                user {
                    id
                    givenName
                    familyName
                    registered
                }
                conversation {
                    id
                    name
                    createdAt
                }
            }
            nextToken
        }
    }
`;

export const getCompany = gql`
    query getCompany($id: ID!) {
        getCompany(id: $id) {
            id
            name
            email
            website
            size
            location
            description
            avatar {
                ...S3ObjectFragment
            }
            facebook
            twitter
            owner {
                id
                familyName
                givenName
                avatar {
                    ...S3ObjectFragment
                }
            }
            phoneNumber
            linkedIn
            facebook
            yearFounded
            about
            specialties
            businessType
            licenseNumber
            licenseState
            verifications {
                ...S3ObjectFragment
            }
        }
    }
    ${s3Object}
`;

export const getCompanyAvatar = gql`
    query getCompany($id: ID!) {
        getCompany(id: $id) {
            id
            avatar {
                ...S3ObjectFragment
            }
        }
    }
    ${s3Object}
`;

// todo, add more attributes to this query, currently does not work
export const listCompany = gql`
    query listCompany {
        listCompany {
            items {
                id
                name
            }
        }
    }
`;

export const listCategories = gql`
    query listTopLevelProjectCategories {
        listTopLevelProjectCategories {
            name
            subcategories {
                name
            }
        }
    }
`;
