import * as React from 'react';
import { Auth, JS, Logger } from 'aws-amplify';
import AuthPiece from './AuthPiece';
import TOTPSetupComp from './TOTPSetupComp';

const logger = new Logger('TOTPSetup');

export default class TOTPSetup extends AuthPiece {
    constructor(props) {
        super(props);

        this.validAuthStates = ['TOTPSetup'];
        this.onTOTPEvent = this.onTOTPEvent.bind(this);
        this.checkContact = this.checkContact.bind(this);
    }

    checkContact(user) {
        Auth.verifiedContact(user).then(data => {
            if (!JS.isEmpty(data.verified)) this.changeState('signedIn', user);
            else {
                const newUser = Object.assign(user, data);
                this.changeState('verifyContact', newUser);
            }
        });
    }

    onTOTPEvent(event, data, user) {
        logger.debug('on totp event', event, data);
        if (event === 'Setup TOTP' && data === 'SUCCESS')
            this.checkContact(user);
    }

    showComponent() {
        const { hide } = this.props;

        if (hide && hide.includes(TOTPSetup)) return null;

        return <TOTPSetupComp {...this.props} onTOTPEvent={this.onTOTPEvent} />;
    }
}
