import React, { useState, useEffect } from 'react';
import { bool, shape, string, oneOfType, number, func } from 'prop-types';
import classNames from 'classnames';
import { withApollo } from 'react-apollo';
import { compose } from 'redux';
import ContentLoader from 'react-content-loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrinAlt } from '@fortawesome/pro-solid-svg-icons';
import Gravatar from 'react-gravatar';
import withUser from '../withUser';
import getAvatar from '../../util/getAvatar';

const Avatar = ({
    loading,
    user,
    customUser,
    client,
    size,
    className,
    // Destructure so we don't pass along to DOM
    signOut,
    ...props
}) => {
    /* eslint-disable react/jsx-props-no-spreading */
    const { avatar, givenName, gravatar } = customUser || user || {};
    const { key } = avatar || {};
    const [currentAvatar, setCurrentAvatar] = useState(null);

    useEffect(() => {
        if (key) {
            const s3Url = getAvatar(key);
            Promise.resolve(s3Url)
                .then(url => {
                    setCurrentAvatar(url);
                })
                .catch(err => console.error(err));
        } else {
            setCurrentAvatar(null);
        }
    }, [key]);

    const diameter =
        typeof size === 'string' ? Number(size.replace(/\D/g, '')) : size;
    const radius = diameter / 2;
    const dimensions = { width: diameter, height: diameter };
    const imageProps = { ...dimensions, ...props };
    if (loading || !user)
        return (
            <ContentLoader
                className={className}
                style={dimensions}
                {...imageProps}
            >
                <circle cx={radius} cy={radius} r={radius} />
            </ContentLoader>
        );

        const avatarClassNames = classNames(className, 'rounded-circle');

    if (gravatar) {
        return (
            <Gravatar
                email={gravatar}
                style={dimensions}
                className={avatarClassNames}
            />
        );
    }

    return currentAvatar ? (
        <img
            alt={givenName}
            src={currentAvatar}
            className={avatarClassNames}
            {...imageProps}
        />
    ) : (
        <FontAwesomeIcon
            icon={faGrinAlt}
            className={className}
            style={{ fontSize: diameter }}
            {...props}
        />
    );
};

Avatar.propTypes = {
    loading: bool,
    user: shape({}),
    customUser: shape({}),
    client: shape({}).isRequired,
    size: oneOfType([string, number]),
    className: string,
    signOut: func.isRequired
};

Avatar.defaultProps = {
    loading: false,
    user: undefined,
    customUser: undefined,
    size: 24,
    className: undefined
};

export default compose(withUser, withApollo)(Avatar);
