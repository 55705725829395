import Loadable from 'react-loadable';
import loading from '../components/Loading';

export const Home = Loadable({
    loader: () => import('./Home'),
    loading
});

export const Project = Loadable({
    loader: () => import('./Project'),
    loading
});

export const ProjectsByCategories = Loadable({
    loader: () => import('./ProjectsByCategories'),
    loading
});

export const User = Loadable({
    loader: () => import('./User'),
    loading
});

/*
 * We don't really need dynamic loading for the Auth pages since most
 * of the Auth component is already imported through global modal
 */
export { SignIn, SignUp, ConfirmSignUp, ResetPassword } from './Auth';

export const Submit = Loadable({
    loader: () => import('./Submit'),
    loading
});

export const Messages = Loadable({
    loader: () => import('./Messages'),
    loading
});

export const Company = Loadable({
    loader: () => import('./Company'),
    loading
});

export const CompanyHomePage = Loadable({
    loader: () => import('./Company/CompanyHomePage'),
    loading
});

export const CompanySearch = Loadable({
    loader: () => import('./Company/CompanySearch'),
    loading
});

export const ValidatedCompanySettings = Loadable({
    loader: () => import('./Company/CompanySettings/ValidatedCompanySettings'),
    loading
});

export const RegisterCompany = Loadable({
    loader: () => import('./Company/RegisterCompany'),
    loading
});

export const MyCompanies = Loadable({
    loader: () => import('./Company/MyCompanies'),
    loading
});

export const ValidatedVerification = Loadable({
    loader: () => import('./Company/CompanySettings/CompanyVerifications'),
    loading
});

export const Finance = Loadable({
    loader: () => import('./Company/Finance'),
    loading
});

export const Marketing = Loadable({
    loader: () => import('./Company/Marketing'),
    loading
});

export const About = Loadable({
    loader: () => import('./About'),
    loading
});

export const Legal = Loadable({
    loader: () => import('./Legal'),
    loading
});

// export const Admin = Loadable({
//     loader: () => import('./Admin'),
//     loading
// });

export const NotFound = Loadable({
    loader: () => import('./NotFound'),
    loading
});
