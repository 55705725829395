import React from 'react';
import { oneOfType, string, number } from 'prop-types';

const Logo = ({ height, fill, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 275 218"
        height={height}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
        <path
            fill={fill}
            fillRule="nonzero"
            d="M172.988 172.676v28.82l-28.638 14.319c-3.905 1.952-10.237 1.952-14.142 0l-127.28-63.64c-3.904-1.952-3.904-5.118 0-7.07l21.75-10.875 101.058 50.529c6.72 3.36 16.366 3.36 23.086 0l24.166-12.083zm21.227-10.613l55.666-27.833 21.749 10.874c3.905 1.953 3.905 5.119 0 7.071l-77.415 38.708v-28.82zm-21.227-29.387v28.82l-28.638 14.319c-3.905 1.952-10.237 1.952-14.142 0l-127.28-63.64c-3.904-1.952-3.904-5.118 0-7.07l21.75-10.875 101.058 50.529c6.72 3.36 16.366 3.36 23.086 0l24.166-12.083zm21.227-10.613L249.88 94.23l21.749 10.874c3.905 1.953 3.905 5.119 0 7.071l-77.415 38.708v-28.82zm-.367-10.997l-42.066-21.033 49.497-24.749L144.711 37 74 72.355l98.63 49.32-28.28 14.14c-3.905 1.952-10.237 1.952-14.142 0L2.928 72.175c-3.904-1.952-3.904-5.118 0-7.07l127.28-63.64c3.905-1.953 10.237-1.953 14.142 0l127.28 63.64c3.905 1.952 3.905 5.118 0 7.07l-77.782 38.891zm-62.28-31.64l-14.142-7.07 28.285-14.143 14.142 7.071-28.284 14.142z"
        />
    </svg>
);

Logo.propTypes = {
    height: oneOfType([string, number]),
    fill: string
};

Logo.defaultProps = {
    height: '1em',
    fill: '#6F42C1'
};

export default Logo;
