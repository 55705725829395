import React from 'react';
import classNames from 'classnames';
import { I18n } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Jumbotron, Container, Row, Col } from 'reactstrap';
import {
    faTwitter,
    faFacebook,
    faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import SocialLink from './SocialLink';
import styles from './Footer.module.scss';

// `href` key uses `<a>` tag to link off-domain
// `to` key uses `<Link />` component to link on-site
const siteLinks = [
    { name: 'Home', to: '/' },
    { name: 'About', to: '/about' },
    { name: 'Blog', href: 'https://blog.pallet.click' },
    { name: 'FAQ', to: '/faq' },
    { name: 'Privacy', to: '/privacy' },
    { name: 'Terms', to: '/terms' }
];

const socialLinks = [
    {
        name: 'Twitter',
        href: process.env.REACT_APP_TWITTER_URL,
        icon: faTwitter
    },
    {
        name: 'Facebook',
        href: process.env.REACT_APP_FACEBOOK_URL,
        icon: faFacebook
    },
    {
        name: 'LinkedIn',
        href: process.env.REACT_APP_LINKEDIN_URL,
        icon: faLinkedin
    }
];

const Spacer = () => (
    <span className={classNames(styles.spacer, 'mx-2')}>&middot;</span>
);

const Footer = () => (
    <Jumbotron
        fluid
        className={classNames('mb-0', 'bg-light', 'text-center', 'text-muted')}
    >
        <Container>
            <Row className="align-items-center">
                <Col
                    xs={12}
                    md={6}
                    className={classNames(
                        'mb-3',
                        'mb-md-0',
                        'text-center',
                        'text-md-left'
                    )}
                >
                    {socialLinks.map((link, i) => {
                        let margin = 'mx-2';
                        if (i === 0) margin = 'mr-2';
                        else if (i === socialLinks.length) margin = 'ml-2';
                        return (
                            <SocialLink
                                key={link.name}
                                className={margin}
                                {...link}
                            />
                        );
                    })}
                </Col>
                <Col
                    xs={12}
                    md={6}
                    className={classNames('text-center', 'text-md-right')}
                >
                    <div>
                        {siteLinks.map(({ name, href, to }, idx) => (
                            <span key={name}>
                                {href ? (
                                    <a href={href}>{name}</a>
                                ) : (
                                    <Link to={to}>{name}</Link>
                                )}
                                {idx + 1 !== siteLinks.length && <Spacer />}
                            </span>
                        ))}
                    </div>
                    <div>
                        &copy; {new Date().getFullYear()} Pallet.click LLC
                        <Spacer />
                        {process.env.NODE_ENV === 'development' ? (
                            <span className="text-danger">
                                {I18n.get('Development Mode')}
                            </span>
                        ) : (
                            I18n.get('All rights reserved')
                        )}
                    </div>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <small>
                        {I18n.get(
                            `This site is operated by Pallet.click, LLC. (“Pallet”), which is not a registered broker dealer. Pallet does not give investment advice, endorsement, analysis or recommendations with respect to any product. All products powered by Pallet’s technology are offered by, and all information related to is the responsibility of the applicable supplier of such product. Neither Pallet nor any of its officers, directors, agents and employees makes any recommendation or endorsement whatsoever regarding any product powered by Pallet's technology. Nothing on this website should be construed as an offer, distribution or solicitation of any product. Pallet does not provide custodial services in connection with any product powered by Pallet’s technology.`
                        )}
                    </small>
                </Col>
            </Row>
        </Container>
    </Jumbotron>
);

export default Footer;
