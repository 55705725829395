import React from 'react';
import { Auth, I18n, Logger } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn } from '@fortawesome/pro-solid-svg-icons';
import AuthPiece from './AuthPiece';

const logger = new Logger('ForgotPassword');

export default class ForgotPassword extends AuthPiece {
    constructor(props) {
        super(props);

        this.send = this.send.bind(this);
        this.submit = this.submit.bind(this);

        this.validAuthStates = ['forgotPassword'];
        this.state = { delivery: null };
    }

    async send(event) {
        if (event) event.preventDefault();
        const { authData = {} } = this.props;
        const username = this.inputs.username || authData.username;

        try {
            const data = await Auth.forgotPassword(username);
            logger.debug(data);
            this.setState({ delivery: data.CodeDeliveryDetails });
        } catch (error) {
            this.error(error);
        }
    }

    async submit(event) {
        if (event) event.preventDefault();
        const { authData = {} } = this.props;
        const { code, password } = this.inputs;
        const username = this.inputs.username || authData.username;

        try {
            const data = await Auth.forgotPasswordSubmit(
                username,
                code,
                password
            );
            logger.debug(data);
            this.changeState('signIn');
            this.setState({ delivery: null });
        } catch (error) {
            this.error(error);
        }
    }

    usernameView() {
        return (
            <FormGroup row>
                <Label for="username" xs={3}>
                    {I18n.get('User')}
                </Label>
                <Col xs={9}>
                    <Input
                        type="text"
                        name="username"
                        id="username"
                        placeholder={I18n.get('Email or Phone')}
                        autoFocus
                        required
                        onChange={this.handleInputChange}
                    />
                </Col>
            </FormGroup>
        );
    }

    submitView() {
        return (
            <>
                {this.usernameView()}
                <FormGroup row>
                    <Label for="password" xs={3}>
                        {I18n.get('Password')}
                    </Label>
                    <Col xs={9}>
                        <Input
                            type="password"
                            name="password"
                            id="password"
                            required
                            onChange={this.handleInputChange}
                        />
                    </Col>
                </FormGroup>
            </>
        );
    }

    showComponent() {
        const { authData = {}, onModalToggle } = this.props;
        const showSubmit =
            this.state.delivery || (authData && authData.username);
        const changeToSignIn = () => this.changeState('signIn');
        const SignInLinkContents = () => (
            <>
                <FontAwesomeIcon icon={faSignIn} className="mr-2" />
                {I18n.get('Back to Sign In')}
            </>
        );
        const BackToSignInLink = () =>
            onModalToggle ? (
                <Button
                    color="link"
                    onClick={changeToSignIn}
                    className="p-0 border-0"
                >
                    <SignInLinkContents />
                </Button>
            ) : (
                <Link to="/sign-in" onClick={changeToSignIn}>
                    <SignInLinkContents />
                </Link>
            );
        return (
            <Form onSubmit={showSubmit ? this.submit : this.send}>
                <div className="mb-4">
                    {I18n.get(
                        "Enter your email or phone number, and we'll send you a code to reset your password."
                    )}
                </div>
                {showSubmit ? this.submitView() : this.usernameView()}
                <Row className="mt-4 d-flex align-items-center">
                    <Col xs="auto">
                        {showSubmit ? (
                            <Button color="secondary" onClick={this.send}>
                                {I18n.get('Resend Code')}
                            </Button>
                        ) : (
                            <BackToSignInLink />
                        )}
                    </Col>
                    <Col className="text-right">
                        <Button
                            type="submit"
                            color="primary"
                            size="lg"
                            disabled={this.state.loading}
                        >
                            {I18n.get(showSubmit ? 'Submit' : 'Send Code')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}
