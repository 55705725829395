import { Component } from 'react';
import { shape, string, oneOfType, func } from 'prop-types';

class AuthPiece extends Component {
    static errorMessage(err) {
        if (typeof err === 'string') return err;
        return err.message || JSON.stringify(err);
    }

    static showComponent() {
        throw new Error(
            'You must implement showComponent() set this.validAuthStates.'
        );
    }

    constructor(props) {
        super(props);

        this.inputs = {};

        this.validAuthStates = [];
        this.changeState = this.changeState.bind(this);
        this.error = this.error.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    // Extract username from authData
    usernameFromAuthData() {
        let username = '';
        const { authData } = this.props;
        if (!authData) return username;
        if (typeof authData === 'object')
            // user object
            username = authData.user
                ? authData.user.username
                : authData.username;
        else username = authData; // username string
        return username;
    }

    triggerAuthEvent(event) {
        const { onAuthEvent } = this.props;
        if (onAuthEvent) onAuthEvent(event);
    }

    changeState(state, data) {
        const { onStateChange } = this.props;
        if (onStateChange) onStateChange(state, data);
        this.triggerAuthEvent({
            type: 'stateChange',
            data: state
        });
    }

    error(err) {
        this.triggerAuthEvent({
            type: 'error',
            data: this.constructor.errorMessage(err)
        });
    }

    handleInputChange({ target }) {
        this.inputs = this.inputs || {};
        const { name, value, type, checked } = target;
        const checkType = ['radio', 'checkbox'].includes(type);
        this.inputs[name] = checkType ? checked : value;
        this.inputs.checkedValue = checkType ? value : null;
    }

    render() {
        const { authState } = this.props;
        if (!this.validAuthStates.includes(authState)) {
            this.inputs = {};
            return null;
        }
        return this.showComponent();
    }
}

AuthPiece.propTypes = {
    authState: string,
    authData: oneOfType([shape({}), string]),
    onStateChange: func,
    onAuthEvent: func
};

AuthPiece.defaultProps = {
    authState: 'signIn',
    authData: {},
    onStateChange: undefined,
    onAuthEvent: undefined
};

export default AuthPiece;
