import React, { Component } from 'react';
import { shape, func } from 'prop-types';
import { Auth, I18n, Logger } from 'aws-amplify';
import QRCode from 'qrcode.react';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';

const logger = new Logger('TOTPSetup');

export default class TOTPSetupComp extends Component {
    static propTypes = {
        authData: shape({
            user: shape({}).isRequired
        }).isRequired,
        onTOTPEvent: func
    };

    static defaultProps = {
        onTOTPEvent: undefined
    };

    constructor(props) {
        super(props);

        this.setup = this.setup.bind(this);
        this.showSecretCode = this.showSecretCode.bind(this);
        this.verifyTotpToken = this.verifyTotpToken.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.triggerTOTPEvent = this.triggerTOTPEvent.bind(this);

        this.state = {
            code: null,
            setupMessage: null
        };
    }

    componentDidMount() {
        this.setup();
    }

    async setup() {
        this.setState({ setupMessage: null });
        const {
            authData: { user }
        } = this.props;

        try {
            const data = await Auth.setupTOTP(user);
            logger.debug('secret key', data);
            const code = `otpauth://totp/AWSCognito:${
                user.username
            }?secret=${data}&issuer=AWSCognito`;
            this.setState({ code });
        } catch (error) {
            logger.debug('totp setup failed', error);
        }
    }

    handleInputChange(evt) {
        this.setState({ setupMessage: null });
        this.inputs = {};
        const { name, value, type, checked } = evt.target;
        const checkType = ['radio', 'checkbox'].includes(type);
        this.inputs[name] = checkType ? checked : value;
    }

    triggerTOTPEvent(event, data, user) {
        const { onTOTPEvent } = this.props;
        if (onTOTPEvent) onTOTPEvent(event, data, user);
    }

    verifyTotpToken() {
        if (!this.inputs) {
            logger.debug('no input');
            return;
        }
        const {
            authData: { user }
        } = this.props;
        const { totpCode } = this.inputs;

        Auth.verifyTotpToken(user, totpCode)
            .then(() => {
                // Set it to preferred mfa
                Auth.setPreferredMFA(user, 'TOTP');
                this.setState({
                    setupMessage: '2-Factor Authentication setup successfully'
                });
                logger.debug('TOTP setup success!');
                this.triggerTOTPEvent('Setup TOTP', 'SUCCESS', user);
            })
            .catch(err => {
                this.setState({
                    setupMessage: '2-Factor Authentication setup failed'
                });
                logger.error(err);
            });
    }

    render() {
        const { code, setupMessage } = this.state;

        return (
            <Form onSubmit={this.verifyTotpToken}>
                <div className="mb-4">
                    {I18n.get('Scan then enter verification code')}
                </div>
                <FormGroup row>
                    {code && (
                        <>
                            <FormGroup row>
                                <QRCode value={code} />
                            </FormGroup>
                            <FormGroup row>
                                <Label for="totpCode" xs={3}>
                                    {I18n.get('Code')}
                                </Label>
                                <Col xs={9}>
                                    <Input
                                        type="number"
                                        placeholder="000000"
                                        key="totpCode"
                                        name="totpCode"
                                        autoFocus
                                        autoComplete="off"
                                        required
                                        onChange={this.handleInputChange}
                                    />
                                    {setupMessage && I18n.get(setupMessage)}
                                </Col>
                            </FormGroup>
                        </>
                    )}
                </FormGroup>
                <Row className="mt-4">
                    <Col className="text-right">
                        <Button type="submit" color="primary" size="lg">
                            {I18n.get('Verify Token')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}
