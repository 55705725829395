import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import AuthPiece from './AuthPiece';

export default class Loading extends AuthPiece {
    constructor(props) {
        super(props);

        this.validAuthStates = ['loading'];
    }

    static showComponent() {
        return (
            <FontAwesomeIcon
                icon={faSpinnerThird}
                size="3x"
                spin
                className="m-5"
            />
        );
    }
}
