import React from 'react';
import { Auth, I18n, JS } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn } from '@fortawesome/pro-solid-svg-icons';
import AuthPiece from './AuthPiece';

export default class ConfirmSignIn extends AuthPiece {
    constructor(props) {
        super(props);

        this.validAuthStates = ['confirmSignIn'];

        this.checkContact = this.checkContact.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            mfaType: 'SMS'
        };
    }

    async checkContact(user) {
        const data = await Auth.verifiedContact(user);
        if (!JS.isEmpty(data.verified)) this.changeState('signedIn', user);
        else {
            const newUser = { ...user, ...data };
            this.changeState('verifyContact', newUser);
        }
    }

    componentDidUpdate() {
        /* eslint-disable react/no-did-update-set-state */
        // logger.debug('component did update with props', this.props);
        const user = this.props.authData;
        const mfaType =
            user && user.challengeName === 'SOFTWARE_TOKEN_MFA'
                ? 'TOTP'
                : 'SMS';
        // eslint-disable-next-line react/no-did-update-set-state
        if (this.state.mfaType !== mfaType) this.setState({ mfaType });
    }

    async handleSubmit(event) {
        if (event) event.preventDefault();
        const {
            authData: { user }
        } = this.props;
        const { code } = this.inputs;
        const mfaType =
            user.challengeName === 'SOFTWARE_TOKEN_MFA'
                ? 'SOFTWARE_TOKEN_MFA'
                : null;
        try {
            await Auth.confirmSignIn(user, code, mfaType);
            this.checkContact(user);
        } catch (error) {
            this.error(error);
        }
    }

    showComponent() {
        const { onModalToggle } = this.props;
        const { mfaType } = this.state;
        const changeToSignIn = () => this.changeState('signIn');
        const SignInLinkContents = () => (
            <>
                <FontAwesomeIcon icon={faSignIn} className="mr-2" />
                {I18n.get('Back to Sign In')}
            </>
        );
        return (
            <Form onSubmit={this.handleSubmit}>
                <FormGroup row>
                    <Label for="code" xs={3}>
                        {I18n.get(`Confirm ${mfaType} Code`)}
                    </Label>
                    <Col xs={9}>
                        <Input
                            type="number"
                            name="code"
                            id="code"
                            key="code"
                            autoFocus
                            required
                            autoComplete="off"
                            onChange={this.handleInputChange}
                        />
                    </Col>
                </FormGroup>
                <Row className="mt-4 d-flex align-items-center">
                    <Col xs="auto">
                        {onModalToggle ? (
                            <Button
                                color="link"
                                onClick={changeToSignIn}
                                className="p-0 border-0"
                            >
                                <SignInLinkContents />
                            </Button>
                        ) : (
                            <Link to="/sign-in" onClick={changeToSignIn}>
                                <SignInLinkContents />
                            </Link>
                        )}
                    </Col>
                    <Col className="text-right">
                        <Button
                            type="submit"
                            color="primary"
                            size="lg"
                            disabled={this.state.loading}
                        >
                            {I18n.get('Confirm')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}
