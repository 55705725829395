import React, { useReducer, useState, useEffect } from 'react';
import { Auth, Logger, Hub } from 'aws-amplify';

const logger = new Logger('withSession');

const amplifySessionReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_USER_SESSION_INIT':
            return {
                ...state,
                loading: true,
                error: null
            };
        case 'FETCH_USER_SESSION_SUCCESS':
            return {
                ...state,
                loading: false,
                error: null,
                session: action.payload.session
            };
        case 'FETCH_USER_SESSION_FAILURE':
            return { ...state, loading: false, error: action.payload.error };
        case 'RESET_USER_SESSION':
            return { ...state, session: null };
        default:
            throw new Error();
    }
};

const initialState = {
    loading: true,
    error: null,
    session: null
};

const withSession = WrappedComponent => {
    const SessionComponent = props => {
        const [{ loading, error, session }, dispatch] = useReducer(
            amplifySessionReducer,
            initialState
        );
        const [triggerFetch, setTriggerFetch] = useState(false);

        useEffect(() => {
            let isMounted = true;

            const fetchUserSession = async () => {
                if (isMounted) {
                    dispatch({ type: 'FETCH_USER_SESSION_INIT' });
                    try {
                        const data = await Auth.currentSession();
                        if (data)
                            dispatch({
                                type: 'FETCH_USER_SESSION_SUCCESS',
                                payload: { session: data }
                            });
                        else
                            dispatch({
                                type: 'FETCH_USER_SESSION_FAILURE',
                                payload: {
                                    error: new Error('No session data returned')
                                }
                            });
                    } catch (err) {
                        logger.debug('No current user session');
                        dispatch({
                            type: 'FETCH_USER_SESSION_FAILURE',
                            payload: { error: err }
                        });
                    }
                }
            };

            const HubListener = () => {
                Hub.listen('auth', data => {
                    const { payload } = data;
                    if (isMounted)
                        switch (payload.event) {
                            case 'signIn':
                                setTriggerFetch(true);
                                break;
                            case 'signOut':
                                setTriggerFetch(false);
                                break;
                            default:
                                break;
                        }
                });
            };

            HubListener();
            fetchUserSession();

            return () => {
                Hub.remove('auth');
                isMounted = false;
            };
        }, [triggerFetch]);

        return (
            <WrappedComponent
                loading={loading}
                error={error}
                session={session}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
            />
        );
    };

    return SessionComponent;
};

export default withSession;
