// Action Types
const HIDE_HOME_BANNER = 'pallet/appearance/HIDE_HOME_BANNER';

// Reducer
export default (
    state = {
        activeFilter: undefined,
        showBanner: true
    },
    action = {}
) => {
    switch (action.type) {
        case HIDE_HOME_BANNER:
            return {
                ...state,
                showBanner: false
            };
        default:
            return state;
    }
};

// Action Creators
export const hideHomeBanner = () => ({
    type: HIDE_HOME_BANNER
});
